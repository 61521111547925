import React, { lazy } from 'react';
import { dashboardMenu, authMenu, businessMenu, settingsMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/AuthPage')),
	CONFIRM_SIGNUP: lazy(() => import('../pages/auth/ConfirmSignUp')),
	CONFIRM_LOGIN: lazy(() => import('../pages/auth/ConfirmLogin')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/ForgetPage')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/ConfirmReset')),
	RESET_PASSWORD: lazy(() => import('../pages/auth/ResetPassword')),
	JOIN_TEAM: lazy(() => import('../pages/auth/JoinTeam')),
};

const LANDING = {
	GET_STARTED: lazy(() => import('../pages/dashboard/GetStarted')),
	DASHBOARD: lazy(() => import('../pages/dashboard/Dashboard')),
	ALL_PROJECTS: lazy(() => import('../pages/dashboard/AllProjects')),
};

const BUSINESS = {
	WALLET: lazy(() => import('../pages/business/Wallet')),
	VERIFY_DEPOSIT: lazy(() => import('../pages/business/VerifyPayment')),
	SINGLE_TRANSACTION: lazy(() => import('../pages/business/SingleTransaction')),
	RECORDS: lazy(() => import('../pages/business/Records')),
	SINGLE_RECORD: lazy(() => import('../pages/business/SingleRecord')),
	PROFILES: lazy(() => import('../pages/business/Profiles')),
	SINGLE_PROFILE: lazy(() => import('../pages/business/SingleProfile')),
};

const TEAM = {
	ALL_TEAM: lazy(() => import('../pages/team/Team')),
	EDIT_ROLE: lazy(() => import('../pages/team/EditRole')),
};

const BILLING = {
	OVERVIEW: lazy(() => import('../pages/billings/Overview')),
	PLANS: lazy(() => import('../pages/billings/Plans')),
	API_KEYS: lazy(() => import('../pages/billings/ApiKeys')),
};

const SETTINGS = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const presentation = [
	/**
	 * AUTH
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
	},
	{
		path: authMenu.confirmSignUp.path,
		element: <AUTH.CONFIRM_SIGNUP />,
	},
	{
		path: authMenu.confirmLogin.path,
		element: <AUTH.CONFIRM_LOGIN />,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET_PASSWORD />,
	},
	{
		path: authMenu.joinTeam.path,
		element: <AUTH.JOIN_TEAM />,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.getStarted.path,
		element: <LANDING.GET_STARTED />,
	},
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardMenu.projects.path,
		element: <LANDING.ALL_PROJECTS />,
	},
	/**
	 * Business
	 */
	{
		path: businessMenu.wallet.path,
		element: <BUSINESS.WALLET />,
	},
	{
		path: businessMenu.verifyDeposit.path,
		element: <BUSINESS.VERIFY_DEPOSIT />,
	},
	{
		path: `${businessMenu.wallet.path}/transactions/:id`,
		element: <BUSINESS.SINGLE_TRANSACTION />,
	},
	{
		path: businessMenu.records.path,
		element: <BUSINESS.RECORDS />,
	},
	{
		path: `${businessMenu.records.path}/:id`,
		element: <BUSINESS.SINGLE_RECORD />,
	},
	{
		path: businessMenu.profiles.path,
		element: <BUSINESS.PROFILES />,
	},
	{
		path: `${businessMenu.profiles.path}/:id`,
		element: <BUSINESS.SINGLE_PROFILE />,
	},
	{
		path: settingsMenu.team.path,
		element: <TEAM.ALL_TEAM />,
	},
	{
		path: `${settingsMenu.editRole.path}/:id`,
		element: <TEAM.EDIT_ROLE />,
	},
	{
		path: settingsMenu.billing.subMenu.overview.path,
		element: <BILLING.OVERVIEW />,
	},
	{
		path: settingsMenu.billing.subMenu.plans.path,
		element: <BILLING.PLANS />,
	},
	{
		path: settingsMenu.apiKeys.path,
		element: <BILLING.API_KEYS />,
	},
	{
		path: settingsMenu.profile.path,
		element: <SETTINGS.PROFILE />,
	},
];
const contents = [...presentation];

export default contents;
