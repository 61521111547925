export const links = {
	// bearerUrl: 'https://meverify.test/api/v2',
	// galleryUrl: 'https://meverify.test/v2/assets',
	bearerUrl: 'https://api.meverify.com.ng/api/v2',
	galleryUrl: 'https://api.meverify.com.ng/v2/assets',
	paymentRedirectUrl: 'https://account.meverify.com.ng',
	documentationUrl: '',
	pages: {
		// Auth URLs
		login: 'login',
		loginVerify: 'verify-login',
		createAccount: 'create-account',
		createToken: 'create-verification-token',
		checkToken: 'check-verification-token',
		verifyToken: 'verify-verification-token',
		forgotPassword: 'forgot-password',
		resetPassword: 'reset-password',
		logout: 'account/logout',
		// profile
		profile: 'account/profile',
		changePass: 'account/change-password',
		updateProfile: 'account/update-profile',
		toggleTwofactor: 'account/toggle-2-factor',
		// api calls
		apiCalls: 'api-calls',
		// api keys
		apiKeys: 'api-keys',
		revealApiKeys: 'api-keys/reveal-key',
		generateApiKey: 'api-keys/generate-key',
		// staff invitations
		listInvites: 'invites/invitations',
		createInvites: 'invites/create-invite',
		deleteInvites: 'invites/delete-invite',
		// companies
		viewCompany: 'companies',
		editCompany: 'companies/edit-company',
		// dashboard
		dashboard: 'dashboard',
		// Payment Accounts
		paymentAccounts: 'payment-accounts',
		// plans
		plans: 'plans',
		// products
		products: 'products',
		// profiles
		profiles: 'profiles',
		deleteProfile: 'profiles/delete-profile',
		// projects
		projects: 'projects',
		createProject: 'projects/create-project',
		editProject: 'projects/edit-project',
		deleteProject: 'projects/delete-project',
		changeProjectStatus: 'projects/change-status',
		// teams
		teams: 'teams',
		removeMember: 'teams/remove-member',
		// transactions
		transactions: 'transactions',
		// roles
		roles: 'roles',
		createRole: 'roles/create-role',
		editRole: 'roles/edit-role',
		deleteRole: 'roles/delete-role',
		permissions: 'permissions',
		// wallet
		depositWallet: 'wallets/deposit',
		verifyDeposit: 'wallets/verify-deposit',
		wallets: 'wallets',
		currencies: 'wallets/currencies',
		createWallet: 'wallets/create-wallet',
		updateThreshold: 'wallets/update-threshold',
	},
};

export function getLink(link: string) {
	return `${links.bearerUrl}/user/${link}`;
}
