export const dashboardMenu = {
	getStarted: {
		id: 'get-started',
		text: 'Get Started',
		path: '/',
		icon: 'InfoOutline',
		permission: null,
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		permission: null,
	},
	projects: {
		id: 'project',
		text: 'All Projects',
		path: '/projects',
		icon: 'FilterNone',
		permission: 'projects',
	},
};

export const businessMenu = {
	listings: {
		id: 'business',
		text: 'Business',
		icon: 'StackedBarChart',
		permission: 'business',
	},
	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/business/wallet',
		icon: 'MonetizationOn',
		permission: 'wallet',
	},
	verifyDeposit: {
		id: 'wallet-verify-deposit',
		text: 'Wallet Deposit',
		path: '/verify_payment',
		icon: 'MonetizationOn',
		permission: 'wallet',
		hide: true,
	},
	records: {
		id: 'records',
		text: 'Records',
		path: '/busines/records',
		icon: 'Leaderboard',
		permission: 'records',
	},
	profiles: {
		id: 'profiles',
		text: 'Customer Profile',
		path: '/busines/profiles',
		icon: 'AccountTree',
		permission: 'records',
	},
};

export const settingsMenu = {
	listings: {
		id: 'settings',
		text: 'Settings',
		icon: 'Settings',
		permission: 'edit business',
	},
	team: {
		id: 'teams',
		text: 'Teams',
		path: '/settings/team',
		icon: 'Group',
		permission: 'team profile',
	},
	editRole: {
		id: 'edit-role',
		text: 'Edit Role',
		path: '/settings/roles/edit',
		icon: 'Group',
		permission: 'team profile',
		hide: true,
	},
	billing: {
		id: 'billing',
		text: 'Billing',
		path: '/settings/billing',
		icon: 'GridGoldenratio',
		permission: 'billing',
		subMenu: {
			overview: {
				id: 'overview',
				text: 'Overview',
				path: '/settings/billings/overview',
				icon: 'Person',
				permission: 'billing',
			},
			plans: {
				id: 'plans',
				text: 'Plans',
				path: '/settings/billings/plans',
				icon: 'AutoAwesomeMotion',
				permission: 'billing',
			},
		},
	},
	apiKeys: {
		id: 'api_keys',
		text: 'API keys',
		path: '/settings/billings/api_keys',
		icon: 'VpnKey',
		permission: 'api keys',
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/account-settings',
		icon: 'NotificationAdd',
		permission: null,
		hide: true,
	},
	profile: {
		id: 'Profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
		permission: null,
	},
};

export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	confirmLogin: {
		id: 'confirmLogin',
		text: 'Please Verify Your account',
		path: '/login/secured/2fa',
		hide: true,
	},
	joinTeam: {
		id: 'accept_invitation',
		text: 'Join MeVerify Team',
		path: '/invitations',
		hide: true,
	},
	signUp: {
		id: 'register1',
		text: 'Join MeVerify Today',
		path: '/sign-up',
		hide: true,
	},
	confirmSignUp: {
		id: 'confirm_register',
		text: 'Confirm your E-mail',
		path: '/mail-verification',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forget-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/reset-verification',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};
